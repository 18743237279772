@import url("https://fonts.googleapis.com/css?family=Ubuntu");
@import url("https://fonts.googleapis.com/css?family=Ubuntu+Mono");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
@font-face {
  font-family: geomanistfont;
  src: url(assets/fonts/geomanist.ttf);
}

body {
  background-color: #fdfdfd;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.givesomeMargin {
  padding: 35px;
}
a:hover {
  text-decoration: none !important;
}
.button66margin {
  margin: 3px 12px 3px 4px !important;
  padding: 8px !important;
}
.button-66 {
  padding: 1px 8px 4px;
  background-color: #077a58;
  width: 100%;
  max-width: 250px;
  border-radius: 2px;
  border: 0;
  color: #fff;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
}
.btnMaxwd {
  width: auto !important;
}
.button-66:disabled {
  background-color: #049c765d !important;
  color: #fff;
}
.button-66:hover {
  background-color: #049c76;
  color: #fff;
}

.h1clss {
  font-size: 32px;
  line-height: 48px;
}
.h2clss {
  font-size: 28px;
  line-height: 40px;
}
.h3clss {
  font-size: 24px;
  line-height: 32px;
}
.h4clss {
  font-size: 19px;
  line-height: 30px;
}
@media (min-width: 376px) and (max-width: 1150px) {
  .h1clss {
    font-size: 28px;
    line-height: 34px;
  }
  .h2clss {
    font-size: 26px;
    line-height: 28px;
  }
  .h3clss {
    font-size: 22px;
    line-height: 26px;
  }
  .h4clss {
    font-size: 17px;
    line-height: 24px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "geomanistfont", sans-serif;
}

.spinnerMid {
  text-align: center;
  height: 80px;
  width: 80px;
}
.centerDiv {
  width: 100%;
}
.centarize {
  height: 80px;
  width: 80px;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
}

h6 {
  font-size: 14px !important;
}

@media (min-width: 1300px) {
  .container {
    min-width: 1300px !important;
    max-width: 95%;
  }
}

@media (min-width: 1400px) {
  .container {
    min-width: 98% !important;
    max-width: 2000px;
  }
}

.abcdx img {
  width: 100%;
}

/* for feedbackpage  */
.formRowx {
  margin: 2% 0px 5%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15.5px;
}
.formRowx input,
.formRowx textarea {
  margin-bottom: 42px;
  font-size: 16px;
  font-family: Georgia, "Times New Roman", Times, serif;
  padding: 4px;
  height: auto;
}

/* for tutorial page  */
.colcleann {
  margin: 2% 0 2%;
}
.colcleann h3 {
  margin-bottom: 3%;
}

/* phone view  */
@media (max-width: 768px) {
  .container {
    width: 100% !important;
  }
  .afterCarousellx {
    display: none;
  }
  .firLogo {
    width: 85% !important;
  }
  .buttonContainer {
    padding: 10px;
  }
  .containerContent {
    padding: 12px !important;
  }
  .firmamentoDiv {
    margin: 0px 0px 25px !important;
  }
  .softbngrp {
    padding: 10px !important;
  }
  .softbngrp a {
    margin-bottom: 12px;
  }
  .blazarImg {
    margin-top: 18px;
  }
  .onlineDot {
    top: 10px !important;
    left: 14px;
  }
  .onlineDot2 {
    top: 10px !important;
  }
  .itemleftright {
    margin-top: 20px !important;
  }
  .fulwid {
    margin-top: 18px !important;
  }
  .lftt {
    float: left !important;
  }
  .helpThing {
    margin: 0px 0px 18px !important;
  }
  .MuiPaper-root {
    width: 100% !important;
  }
  /* sed area  */
}

.showLoadingOverAladin {
  width: 100%;
  background-color: white;
  border-radius: 5px;
  padding-top: 100px;
  height: 806px;
  position: absolute;
  top: 10px;
}

/* for tooltip  */

.mytooltipc {
  cursor: pointer;
  position: relative !important;
  display: inline-block;
}

.mytooltipc .imageTooltip {
  visibility: hidden;
  width: 257px;
  background-color: #04112d;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 3px !important;
  position: absolute;
  z-index: 1;
  top: -204px;
  left: 110%;
}
.imgTtAerror {
  top: -50px !important;
}

.mytooltipc .imageTooltip::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 3px;
  border-style: solid;
  border-color: transparent #04112d transparent transparent;
}
.mytooltipc:hover .imageTooltip,
.mytooltipc:hover .imageTooltip2,
.mytooltipc:hover .imageTooltip3 {
  visibility: visible;
}
.imageTooltip img {
  border-radius: 5px;
}
/* modified tooltip  */

.mytooltipc .imageTooltip3 {
  font-size: 12px;
  visibility: hidden;
  width: 370px;
  border: 2px solid #04112d;
  background-color: #04112d;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 110%;
}

.mytooltipc .imageTooltip3::after {
  content: "";
  position: absolute;
  top: 16px;
  right: 100%;
  margin-top: -5px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent #04112d transparent transparent;
}

/* for second one  */
.imageTooltip2 {
  font-size: 12px;
  visibility: hidden;
  width: 250px;
  background-color: #04112d;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: 0px;
  right: 110%;
}
.mytooltipc .imageTooltip2::after {
  content: "";
  position: absolute;
  top: 17px;
  left: 100%;
  margin-top: -5px;
  border-width: 6px;
  border-style: solid;
  border-color: transparent #04112d transparent transparent;
}
/* done  */

.marpaddesc {
  position: relative;
  display: block;
  margin: 58px 28px 25px;
}
.marpaddesc::after {
  content: "";
  position: absolute;
  left: 50%;
  margin-left: -100px;
  bottom: -15px;
  width: 200px;
  border-bottom: 3px solid #023c3c;
}
.marpaddesc2 {
  margin: 0px 28px !important;
}
.partDescSpn {
  font-size: 15px;
  font-weight: normal;
  color: rgb(65, 65, 65);
}

/* participants section  */

.circle-tile {
  margin-bottom: 15px;
  text-align: center;
}
.circle-tile-heading {
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 100%;
  color: #ffffff;
  height: 80px;
  margin: 0 auto -40px;
  position: relative;
  transition: all 0.3s ease-in-out 0s;
  width: 80px;
}
.circle-tile-heading .fa {
  line-height: 80px;
}
.circle-tile-content {
  padding-top: 50px;
}
.circle-tile-number {
  font-size: 26px;
  font-weight: 700;
  line-height: 1;
  padding: 5px 0 15px;
}
.circle-tile-description {
  text-transform: uppercase;
}
.circle-tile-footer {
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(255, 255, 255, 0.5);
  display: block;
  padding: 5px;
  transition: all 0.3s ease-in-out 0s;
}
.circle-tile-footer:hover {
  background-color: rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
}
.circle-tile-heading.dark-blue:hover {
  background-color: #104568;
}

.circle-tile-heading.blue:hover {
  background-color: #1669a0;
}
.tile-img {
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.9);
}

.dark-blue {
  background-color: #0f517d;
}
.blue {
  background-color: #176ba3;
}

.text-faded {
  color: rgba(255, 255, 255, 0.7);
}
.refreshBtn {
  color: white;
  display: inline-block;
  cursor: pointer;
  position: relative;
  top: -2px;
}
.refreshBtn span {
  /* background-color: #1cf4c9; */
  border-radius: 6px;
  padding: 0px 4px 0px;
}
.clientNotice {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.clientNotice p {
  border-radius: 5px;
  background-color: rgba(255, 0, 0, 0.185);
  text-align: center;
  padding: 5px;
  width: 50%;
  border: 2px solid red;
}

@keyframes rotateRf {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.refresh-start {
  animation-name: rotateRf;
  animation-duration: 1.75s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-play-state: running;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
}

/* for imagepage.js  */
.h1marginpad {
  margin: 10px 0 5px 0;
}
@media screen and (min-width: 990px) {
  .nasaapod {
    padding-right: 4px;
  }
  .newsDivvx {
    padding-top: 58px;
  }
}
.newsLayout {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  color: #000;
  font-weight: 600;
  padding: 3px 0px 3px 8px;
  margin-bottom: 18px;
  border-left: 7px solid #4caf50;
  background: #f2f2f2;
}

.nasaPicIg {
  padding-top: 15px;
}
.imgdesc {
  background-color: rgb(248, 247, 247);
  line-height: 1.37;
  font-weight: 400;
  font-size: 0.95rem;
  border-radius: 4px;
  padding: 12px;
  margin: 2px 15px 8px;
}
.apodDivv {
  box-shadow: 0 2px 2px rgba(50, 50, 93, 0.1), 0 2px 2px rgba(50, 50, 93, 0.15),
    0 2px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 46px;
  padding-bottom: 12px;
}

.imgIconx {
  width: 100%;
  height: 178px;
}
.tvInfo {
  text-align: center;
  margin-top: 8px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  letter-spacing: -0.5px;
  font-size: 1.05rem;
  font-weight: 600;
}
.clickableDivv {
  margin-bottom: 18px;
  margin-right: 2.5%;
  border: 1px solid transparent;
  padding: 15px;
  cursor: pointer;
}
.clickableDivv:hover {
  color: #4caf50;
  border: 1px solid rgb(218, 218, 218);
  background-color: #e2ffe3;
  border-radius: 4px;
}
.bordbtmm {
  display: inline-block;
  padding: 0px 6px 2px 0px;
  margin-bottom: 8px;
  border-bottom: 4px solid #4caf50;
}

/* for newslayout  */
.newsImgWidtth {
  max-width: 100%;
}
.newsRowItem {
  margin-top: 24px;
  padding-bottom: 8px;
  border-bottom: 1px solid #ededed;
  margin-bottom: 32px;
}
@media screen and (min-width: 576px) {
  .colNolftpd {
    padding-left: 0 !important;
  }
}
@media screen and (max-width: 576px) {
  .colNolftpd {
    margin-top: 18px;
  }
}

.h4newstitle {
  font-size: 0.96rem;
  cursor: pointer;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 6px;
}
.h4newstitle:hover {
  color: #2cac31;
}
.dimTextt {
  color: grey;
  font-size: 12px;
  margin-bottom: 6px;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 1200px;
    margin: 1.75rem auto;
  }
}

/* resources page : exploresection.js  */
.Mui-selected {
  color: rgb(10, 110, 224) !important;
}
.MuiCardContent-root,
.MuiBox-root {
  padding: 0px 3px 2px 3px !important;
}

.MuiButtonBase-root,
.MuiTab-root,
.MuiTouchRipple-root {
  padding: 8px !important;
  text-transform: none !important;
  font-weight: 700 !important;
  color: rgb(71, 71, 71) !important ;
}
.MuiBox-root p {
  margin-top: 12px;
  display: block;
  margin-bottom: 0px;
}
.MuiBox-root iframe {
  border: none;
}

/* resourcepage.js  */
.learnMoreBtnx {
  padding: 2px 3px 4px !important;
  max-width: 120px;
  margin-top: 20px !important;
  margin-bottom: 40px !important;
}

.dropdown {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #525f7f;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0.3rem;
  box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1),
    0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
}

.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.3rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f6f9fc;
}

.dropdown-menu.show {
  display: block;
}

.navbarNavg {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbarNavg .dropdown-menu {
  position: static;
  float: none;
}

.navbarExpand {
  flex-flow: row nowrap;
  justify-content: flex-start;
  padding: 0;
}

.navbarExpand .navbarNavg {
  flex-direction: row;
}

.navbarExpand .navbarNavg .dropdown-menu {
  position: absolute;
}

.navvLink {
  display: block;
  padding-bottom: 0 !important;
}

.navvLink i:not(.fa) {
  position: relative;
  top: 2px;
}

.rowVidss {
  margin: 1% auto;
  padding: 16px;
}
.bothVids {
  margin-top: 30px;
}
.navbarNavg .navvLink {
  font-size: 0.88rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  text-transform: normal;
  letter-spacing: 0;
  transition: all 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .navbarNavg .navvLink {
    transition: none;
  }
}

.navbarNavg .navvLink .nav-link-inner--text {
  font-size: 1.03em;
  font-family: "Rubik", sans-serif;
  color: rgb(89, 189, 255);
  font-weight: 600;
}

/*
#4caf50;
*/

.navbarNavg {
  margin-left: 18px;
}
.navbarNavg .navvLink {
  margin-bottom: 12px;
}

@media (min-width: 500px) {
  .navbarNavg .navIttem {
    margin-right: 0.5rem;
  }
  .navbarNavg .navIttem [data-toggle="dropdown"]::after {
    transition: all 0.15s ease;
  }
  .navbarNavg .navIttem.show [data-toggle="dropdown"]::after {
    transform: rotate(180deg);
  }
  .navbarNavg .navvLink {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 0.25rem;
  }
  .navbarNavg .navvLink i {
    margin-right: 0.625rem;
  }
  .navbarNavg .dropdown-menu {
    opacity: 0;
    pointer-events: none;
    margin: 0;
  }
  .navbarNavg .dropdown-menu:before {
    background: #fff;
    box-shadow: none;
    content: "";
    display: block;
    height: 16px;
    width: 16px;
    left: 5px;
    position: absolute;
    bottom: 100%;
    transform: rotate(-45deg) translateY(1rem);
    z-index: -5;
    border-radius: 0.2rem;
  }
  .navbarNavg:not(.navbarHovering) .dropdown-menu.show {
    opacity: 1;
    pointer-events: auto;
    animation: show-navbar-dropdown 0.25s ease forwards;
  }
  .navbarNavg:not(.navbarHovering) .dropdown-menu.close {
    display: block;
    animation: hide-navbar-dropdown 0.15s ease backwards;
  }
  .navbarNavg.navbarHovering .dropdown-menu {
    opacity: 0;
    display: block;
    pointer-events: none;
    transform: translate(0, 10px) perspective(200px) rotateX(-2deg);
    transition: visibility 0.25s, opacity 0.25s, transform 0.25s;
    animation: none;
  }
  .navbarNavg.navbarHovering .navIttem.dropdown:hover > .dropdown-menu {
    display: block;
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translate(0, 0);
    animation: none;
  }
  .navbarNavg.navbarHovering
    .navIttem.dropdown
    > .dropdown-menu
    .dropdown-item.open
    + .dropdown-menu {
    display: block;
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translate(0, 0);
    animation: none;
  }
  .navbarNavg.navbarHovering
    .navIttem.dropdown
    > .dropdown-menu
    .dropdown-item
    + .dropdown-menu {
    margin-left: 10px;
  }
  .navbarNavg.navbarHovering
    .navIttem.dropdown
    > .dropdown-menu
    .dropdown-item
    + .dropdown-menu:before {
    left: -16px;
    top: 4px;
  }
  .navbarNavg .dropdown-menu-inner {
    position: relative;
    padding: 1rem;
  }
}

@media (max-width: 500px) {
  .navbarNavg .navvLink {
    padding: 0.625rem 0;
    color: #172b4d !important;
  }
  .navbarNavg .dropdown-menu {
    box-shadow: none;
    min-width: auto;
  }
  .navbarNavg .dropdown-menu .media svg {
    width: 30px;
  }
}

@keyframes show-navbar-dropdown {
  0% {
    opacity: 0;
    transform: translate(0, 10px) perspective(200px) rotateX(-2deg);
    transition: visibility 0.25s, opacity 0.25s, transform 0.25s;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes hide-navbar-dropdown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate(0, 10px);
  }
}

.xyzx {
  min-height: 400px;
}
.navBarcontainer {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 4px 6px 0 rgba(0, 0, 0, 0.19);
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    #000205 0%,
    #000409 38%,
    #000409 77%,
    #000205 100%
  );
}
.xabdd {
  box-shadow: 0px 4px 2px -4px rgba(0, 0, 0, 0.4);
}
.xabdd h1 {
  display: block;
  width: 100%;
  margin-top: 4%;
}
.xabdd h6 {
  display: block;
  width: 100%;
}
.xabdd h4 {
  display: block;
  width: 100%;
  margin-top: 2%;
}
.newsRox {
  width: 100%;
}
.newsContentt {
  padding: 20px;
}
.newsRowPad {
  padding: 4% 0 5% 2%;
}
.resExploreh1 {
  margin-top: 1.5%;
  margin-bottom: 2%;
}

.modal-footer .btn-danger {
  padding: 0px 12px 2px !important;
}
.heightWidthIframe {
  padding: 0px 18px;
}

.allRadioHorizonLst label {
  background-color: #ececec;
  font-family: "geomanistfont", sans-serif;
  border-radius: 2px;
  padding: 8px 16px 8px 6px;
}
.allRadioHorizonLst label input {
  margin-right: 5px;
}
.allRadioHorizonLst label span:hover {
  cursor: pointer;
  border-bottom: 2px solid #077a58;
}
.actiRad {
  color: #077a58;
}
.pSurveyBlst {
  text-align: center;
  margin-bottom: 4px;
  font-size: 14px;
}
.pSurveyBlst span {
  font-weight: 500;
}

/* fix accordion on wpeak val display  */
.displayBlastValue .MuiButtonBase-root,
.displayBlastValue .MuiPaper-root {
  width: 100% !important;
  padding: 0 !important;
  background-color: unset !important;
  box-shadow: none;
  color: black !important;
  min-height: 0px;
}
.displayBlastValue .MuiButtonBase-root p {
  font-weight: 400 !important;
  text-align: end;
}
.displayBlastValue > div {
  width: 100%;
}
.displayBlastValue .MuiAccordionSummary-content {
  margin: 0 !important;
}
.accordionTtielDiv {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.displayBlastValue .MuiAccordionDetails-root {
  padding: 8px !important;
}
.displayBlastValue .MuiAccordionDetails-root pre {
  padding: 3px 12px !important;
  /* font-family: Verdana, Geneva, Tahoma, sans-serif; */
  font-size: 14px;
  font-weight: 600;
  margin: 0 !important;
}
.displayBlastValue .Mui-expanded {
  min-height: 0px !important;
}
.vouImgHolder {
  text-align: center;
}
/* layout */
.footuppr {
  background-color: #010d1f;
  padding: 50px 0px 10px;
  color: rgba(255, 255, 255, 1);
}
.footdown .container .row .copyright-text {
  padding-top: 24px;
  border-top: 1px solid rgba(58, 107, 252, 0.2);
}
.footerbrand,
.footerlnks,
.footerns {
  padding: 10px 25px;
}
.footerbrand {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.8);
}

ul {
  list-style: none;
}

.footerlnks h4 {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 3px;
}
.footerlnks li {
  padding: 2px 3px;
}
.footerlnks a,
.ackFirm {
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
}
.footerlnks a:hover,
.ackFirm:hover {
  color: #00c897;
}

.footerns h4 {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-bottom: 10px;
}
.footerns p {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 0;
}
.far {
  width: 100%;
  height: 100%;
}
.input-group {
  margin-top: 20px;
}
.footerlnks h4,
.footerns h4 {
  margin: 14px 0;
}

@media (min-width: 768px) {
  .footerlnks,
  .footerns {
    border-left: solid 1px rgba(255, 255, 255, 0.12);
  }
}

.footdown {
  background: #010d1f;
  text-align: center;
  padding: 24px 0;
}
.footdown p {
  margin: 0;
  line-height: 26px;
  font-size: 15px;
  color: #999;
}
.footdown p a,
.hrefTxtt {
  color: #00c897;
}
.hrefTxtt:hover {
  color: #019267;
}
.footdown p a:hover {
  color: #019267;
}
.capthlogo {
  width: 360px;
  margin-bottom: 36px;
}
.footerbrand p {
  line-height: 20px;
}

.copyright-text {
  display: flex;
  justify-content: center;
  gap: 24px;
  align-items: center;
}

.envBtn {
  background-color: #019267;
}
.envBtn:hover {
  background-color: #077a58;
}

/* for header  */

.headbg {
  background-color: black;
  background-image: url("./assets/bg.jpg");
  background-repeat: repeat;
  background-size: contain;
  padding: 8px 0px;
  font-size: 1em;
}

.navlogo {
  margin-right: 40px;
}
.logoImg {
  border-radius: 8px;
}
.navItm {
  cursor: pointer;
  font-weight: 500;
  color: white !important;
  padding: 4px 7px;
}
.navItm::after {
  content: "";
  display: block;
  margin: 2px auto;
  height: 2px;
  width: 0px;
  background: transparent;
  transition: width 0.5s ease, background-color 0.5s ease;
}
.navItm:hover {
  color: #00c897 !important;
}
/*
.navItm:hover:after {
  width: 100%;
  background: #04b30a;
}
*/
.navbar-nav .active {
  color: #00c897 !important;
}
.navbar-nav .active::after {
  width: 100%;
  background: #00c897;
}

@media (max-width: 992px) {
  .navItm {
    margin-left: 0px;
    font-size: 1em;
    font-weight: 500;
  }
}

@media (max-width: 768px) {
  .smallLogo {
    display: none !important;
  }
  .fullLogo {
    display: block !important;
  }
  .navItm {
    margin-left: 0px;
    font-size: 1em;
    letter-spacing: 0.5px;
    font-weight: 500;
  }
  .collapsebg {
    background-color: black;
    margin-top: 10px;
    align-items: center;
  }
  .navbar-nav .active {
    background-color: none !important;
  }
  .footerlnks,
  .footerns {
    margin-top: 20px;
    border-top: solid 1.5px rgba(255, 255, 255, 0.15);
  }
}
.smallLogo {
  display: block;
}
.fullLogo {
  display: none;
}
.modal-dialog-centered {
  max-width: 1100px !important;
}

/* homepage */
.whiteColoredSection {
  padding-top: 85px;
  background-color: #f3f3f2;
}
.whiteColoredSection .containerContent {
  border-radius: 5px;
  background-color: white;
  padding: 30px 30px 10px;
}
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
.custom-tag {
  height: 660px;
  background: black;
}

/* stuff on carousel  */
.buttonContainer {
  pointer-events: none;
  width: 100%;
  position: absolute;
  z-index: 15;
  bottom: 18%;
  left: 0;
}

.firLogo {
  width: 380px;
  height: 100px;
}
.buttonInCarousel {
  font-size: 17px;
  pointer-events: all;
  padding: 5px 20px 8px 10px;
}
.buttonInCarousel2 {
  pointer-events: all;
  font-size: 18px;
  padding: 6px 12px 8px;
}

/* for scroll down button  */
.arrowdownanim {
  position: relative;
  margin-left: 10px;
}
.chevron {
  position: absolute;
  bottom: 20px;
  width: 11px;
  height: 2px;
  opacity: 0;
  transform: scale(0.3);
  -webkit-animation: move-chevron 3s ease-out infinite;
  animation: move-chevron 3s ease-out infinite;
}
.chevron:first-child {
  -webkit-animation: move-chevron 3s ease-out 1s infinite;
  animation: move-chevron 3s ease-out 1s infinite;
}
.chevron:nth-child(2) {
  -webkit-animation: move-chevron 3s ease-out 2s infinite;
  animation: move-chevron 3s ease-out 2s infinite;
}
.chevron:before,
.chevron:after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
  background: #ffffff;
}
.chevron:before {
  left: 0;
  transform: skewY(30deg);
}
.chevron:after {
  right: 0;
  width: 50%;
  transform: skewY(-30deg);
}
@-webkit-keyframes move-chevron {
  25% {
    opacity: 1;
  }
  33.3% {
    opacity: 1;
    transform: translateY(2.28rem);
  }
  66.6% {
    opacity: 1;
    transform: translateY(3.12rem);
  }
  100% {
    opacity: 0;
    transform: translateY(4.8rem) scale(0.5);
  }
}

@keyframes move-chevron {
  25% {
    opacity: 1;
  }
  33.3% {
    opacity: 1;
    transform: translateY(0.4rem);
  }
  66.6% {
    opacity: 1;
    transform: translateY(1rem);
  }
  100% {
    opacity: 0;
    transform: translateY(1.6rem) scale(0.5);
  }
}
/* scroll down button css end  */

.carousel-captiontp {
  text-align: left;
  font-weight: 700;
  word-spacing: 3px;
  letter-spacing: 1px;
  color: white;
  margin-bottom: 52px;
  text-shadow: 0 0 3px rgb(49, 49, 49), 0 0 3px #7af705, 0 0 3px #7af705;
}

.carouselAltText {
  text-align: left;
  pointer-events: none;
  font-weight: 600;
  color: white;
  line-height: 36px;
  text-shadow: 0 0 2px rgb(0, 0, 0), 0 0 2px #7af705, 0 0 2px #7af705;
}

/* @media (max-width: 576px) {
  .carousel-caption {
    left: 0;
    width: 100%;
  }
} */
@media (max-width: 768px) {
  .w-100 {
    width: auto !important;
  }
  .custom-tag {
    height: 480px;
  }
  /* .carousel-caption {
    width: 540px;
    padding: 0;
  } */
  .buttonContainer .container {
    padding: 0 !important;
  }
  .carousel-captiontp {
    margin-bottom: 0px;
    font-size: 2em;
    top: 10px;
  }
  .afterCarousellx {
    height: 165px !important;
    bottom: 220px !important;
    left: 0;
    border: none !important;
    padding: 0 !important;
  }
  .afterCarousellx p {
    font-size: 14px;
  }
  .carouselAltText {
    margin-bottom: 20px !important;
  }
}

@media (min-width: 768px) {
  /* .carousel-caption {
    width: 720px;
  } */
  .carousel-captiontp {
    font-size: 38px;
  }
  .carouselAltText {
    font-size: 22px;
    margin-bottom: 30px;
  }
}
@media (min-width: 992px) {
  .carousel-captiontp {
    font-size: 48px;
  }
  .carouselAltText {
    font-size: 26px;
    margin: 18px 0px 36px;
  }
}
@media (min-width: 1200px) {
  .circle-tile {
    margin: 0px 44px 15px;
    /* margin-bottom: 15px; */
    text-align: center;
  }
  .carousel-captiontp {
    font-size: 58px;
  }
}
.vouheaddesc2 {
  margin-top: 48px;
  color: #222222;
  font-size: 19px;
  line-height: 30px;
  text-align: justify;
}
.vouheaddesc {
  padding: 2px 5px;
  color: #222222;
  font-size: 17px;
  line-height: 30px;
  text-align: justify;
}
.vouheaddesc a,
.vouheaddesc2 a {
  color: #069a8e;
}
.vouheaddesc a:hover,
.vouheaddesc2 a:hover {
  color: #00c897;
}
.afterCarousellxHolder {
  position: relative;
}
.afterCarousellxHolder legend {
  width: auto;
  font-size: 17px;
  padding: 3px 10px;
  font-weight: 100;
  color: white;
  font-family: "Lato", sans-serif;
  background-color: #019267;
  border-radius: 4px;
}
.afterCarousellx fieldset {
  border: 2px solid #019267;
  border-radius: 5px;
  width: 400px;
  height: 220px;
  margin: 1px;
  padding: 9px 8px 0px 14px;
  background-color: rgba(0, 0, 0, 0.3);
}

.afterCarousellx {
  position: absolute;
  bottom: 180px;
  z-index: 1;
  right: 0px;
}
.afterCarousellx p {
  color: white;
}

.softbngrp {
  display: flex;
  justify-content: space-around;
  padding: 24px;
  margin-top: 52px;
}
.softbngrp2 {
  display: flex;
  justify-content: space-around;
  padding: 24px;
  /* gap: 20px; */
}
.mobApp {
  padding-top: 40px;
}

.downTxtt {
  color: #98df9a;
}

/* css for software  */
.someMargin {
  margin-top: 38px;
}
/* 2c3543 */
.vouhead {
  letter-spacing: 0.5px;
  padding: 12px;
  font-size: 1.3em;
  color: white;
  font-weight: 500;
  display: block;
  text-align: center;
  background-color: #273131;
}
.firmamentoDiv {
  background-color: rgb(241, 239, 239);
  margin: 24px;
  text-align: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.firmamentoDiv p {
  text-align: justify;
  font-family: "geomanistfont";
  padding: 18px 38px;
}
.vouimgtag {
  max-width: 93%;
  height: 348px;
  border-radius: 6px;
  padding-bottom: 12px;
  /* border: 1px solid lightgray; */
}
.vouhead2 {
  text-align: center;
  position: relative;
}
.vouhead2:after {
  content: "";
  position: absolute;
  left: 50%;
  margin-left: -40px;
  bottom: -10px;
  width: 80px;
  border-bottom: 3px solid #023c3c;
}
.blazarImg {
  text-align: center;
}
.blazarImg img {
  width: 80%;
  border-radius: 4px;
}
.lastOne {
  padding-bottom: 60px;
}
.imgDescriptionBlaz {
  font-size: 12px;
  text-align: justify;
  margin-top: 6px;
}
.usageStatsP {
  text-align: center;
}

.imageTooltip3 i,
.imageTooltip2 i {
  margin-top: 5px;
  font-style: normal;
  display: inline-block;
  color: #7af705;
  text-decoration: underline;
  text-align: center;
}
.imageTooltip3 li i,
.imageTooltip1 li i {
  text-decoration: none;
  font-weight: bold;
  font-size: 13px;
}
.gettingDataCont {
  width: 100%;
  padding: 20px 30px;
  min-height: 580px;
  background: rgb(254, 254, 254);
  color: black;
  font-family: Ubuntu;
}
.voueachTask {
  display: flex;
  gap: 5px;
  align-items: center;
}
.voueachTaskName {
  margin-bottom: 0px;
  line-height: 20px;
}
.dimvoueachTask {
  opacity: 0.6;
}
.eachTaskSpin {
  width: 20px;
}
.displayThisNone {
  display: none;
}

/* loaderxx sample */
.loaderxx {
  width: 150px; /* control the size */
  aspect-ratio: 1;
  display: grid;
  -webkit-mask: conic-gradient(from 15deg, #0003, #000);
  mask: conic-gradient(from 15deg, #0003, #000);
  animation: load 1s steps(12) infinite;
  color: black;
  width: 17px;
}
.loaderxx,
.loaderxx:before,
.loaderxx:after {
  background: radial-gradient(
        closest-side at 50% 12.5%,
        currentColor 90%,
        #0000 98%
      )
      50% 0/20% 80% repeat-y,
    radial-gradient(closest-side at 12.5% 50%, currentColor 90%, #0000 98%) 0
      50%/80% 20% repeat-x;
}
.loaderxx:before,
.loaderxx:after {
  content: "";
  grid-area: 1/1;
  transform: rotate(30deg);
}
.loaderxx:after {
  transform: rotate(60deg);
}
@keyframes load {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}
.tickHOlderGreen {
  position: relative;
  right: 2px;
  color: #04d832;
}
.showvoulogs2 {
  margin-top: 44px;
}
.terminal__bar {
  display: flex;
  width: 100%;
  height: 35px;
  align-items: center;
  margin-top: 15px;
  padding: 0 8px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #d2d2d292;
}
.terminal__bar p {
  color: #555;
  margin-left: 6px;
  font-size: 14px;
  font-weight: 600;
  line-height: 15px;
  margin-bottom: 0;
}
.terminal__body {
  background-color: #ebebeb66;
  font-family: "Ubuntu Mono";
  min-height: 300px;
  padding: 0px 0px 0px 10px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
}
.terminal__body p {
  margin-bottom: 0;
}

.terminal__body2 {
  max-height: 400px;
  overflow: scroll !important;
}

.scrollableLogs {
  max-height: 450px;
  overflow-x: hidden;
  font-size: 13px;
  overflow-y: auto;
  padding: 5px 5px;
  border-radius: 3px;
}
.scrollableLogs p {
  line-height: 18px !important;
}

/* login page css  */
.Login-Module {
  padding-top: 80px;
  padding-bottom: 80px;
  background: url(./assets/style2img.jpg) no-repeat;
  background-size: cover;
}
.login-holder-cont {
  display: flex;
  justify-content: center;
}
.error-messagearea {
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;
  border: 2px solid red;
  border-radius: 5px;
  background-color: rgba(255, 0, 0, 0.4);
  text-align: center;
  color: white;
  font-weight: 600;
}
.success-messagearea {
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;
  border: 2px solid rgb(1, 81, 1);
  border-radius: 5px;
  background-color: rgb(5, 154, 5);
  text-align: center;
  color: white;
  font-weight: 600;
}
.error-messagearea p,
.success-messagearea p {
  margin: 0;
  padding: 1px 0px 5px;
}
.login-style1 {
  max-width: 600px;
  padding: 40px;
  width: 100%;
  /* padding: 50px 50px 10px; */
  color: #ffffff;
  border: 3px solid black;
  border-radius: 20px;
}
.login-style1 .login-header {
  text-align: center;
  font-weight: bold;
  /* padding: 5px 20px; */
}
.login-style1 .sign-up-txt {
  font-size: 18px;
  color: #ffffff;
  font-weight: 400;
  letter-spacing: 0.05em;
  padding-bottom: 35px;
  padding-top: 0px;
}
.login-style1 .submit {
  display: block;
  margin: 50px auto 0px;
  padding: 0px 30px;
  height: 40px;
  color: #ffffff;
  background: linear-gradient(
    88deg,
    rgba(76, 164, 251, 1) 0%,
    rgba(42, 190, 250, 1) 49%,
    rgba(3, 224, 251, 1) 100%
  );
  box-shadow: 3px 8px 21px rgba(92, 58, 93, 0.25);
  background-color: #17ead9;
  border: 0;
  letter-spacing: 0.1em;
  font-style: 20px;
  border-radius: 25px;
  cursor: pointer;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
.logoutBtnholdd {
  display: flex;
  align-items: center;
  gap: 30px;
}
.logoutBtnholdd p {
  padding: 0px 10px 10px;
  margin-bottom: 0;
  font-size: 22px;
  font-weight: bold;
}
.logoutBtnholdd button {
  max-height: 32px;
}
.everythingHOlder {
  position: relative;
}
.everythingHOlder header {
  height: max-content;
  width: 100%;
}

.alignPCenter {
  text-align: center;
  margin-top: 20px;
}
.errormessageholder,
.successmessageholder {
  margin-top: 10px;
}
.leftright {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
}
.log_upperholder {
  padding: 10px;
}
.log_myfiles_sec {
  display: flex;
  padding: 15px 5px;
  border: 1px solid black;
  border-radius: 5px;
  gap: 10px;
  margin-bottom: 5px;
}
.log_myfiles_sec2 {
  display: flex;
  padding: 5px;
  /* border: 1px solid black; */
  border-radius: 5px;
  gap: 10px;
  margin-bottom: 5px;
}
.log_left_box {
  width: 20%;
}
.filenomarginx {
  margin-bottom: 0;
}
.log_right_box {
  width: 80%;
  padding: 0px 10px;
  display: flex;
  gap: 10px;
}
.custom-file-upload {
  display: inline-block;
  cursor: pointer;
}
.fileInputClass {
  border: 1px solid #068b6a;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 2px 8px;
  color: #2a2929;
}

.fileInputClass:hover {
  background-color: #e9e9e9;
}
#fileInput {
  display: none;
}

.btn-sm {
  line-height: 1 !important;
  font-size: 0.83rem !important;
  padding: 2px 4px !important;
}
.filenomargin2 {
  border-radius: 5px;
  cursor: pointer;
}
.filenomargin2 p {
  margin-bottom: 0 !important;
  padding: 2px 6px 4px;
  color: white;
}

.formRow {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.formRow label {
  min-width: 200px;
  font-weight: 600;
}

.settingsDiv {
  padding: 20px 50px;
  margin-top: 20px;
}
.settingsDiv .myButton {
  margin-top: 30px;
  width: auto;
  justify-content: left;
  max-width: 125px;
}
.settingsDiv input {
  padding: 8px 12px !important;
}

.settingsDiv .MuiButtonBase-root {
  background-color: transparent !important;
  color: #314355 !important;
}
.settingsDiv .MuiInputBase-root button {
  margin-right: 0 !important;
  transition: all 0.4s ease-in;
}
.settingsDiv .MuiInputBase-root button:hover {
  background-color: rgba(0, 0, 0, 0.136) !important;
}
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
}
.settingsDiv .myButton {
  margin-top: 30px;
  width: auto;
  justify-content: left;
  max-width: 125px;
}
.checkInBtn {
  color: #fff;
  background-color: #1cc88a;
  border-color: #1cc88a;
}
.checkInBtn:hover {
  color: #fff;
  background-color: #17a673;
  border-color: #169b6b;
}
.btn-icon-split {
  padding: 0;
  overflow: hidden;
  display: inline-flex;
  align-items: stretch;
  justify-content: center;
}
.btn-icon-split .textt {
  font-size: 16px;
  display: inline-block;
  padding: 0.25rem 0.65rem;
}
.btnicon {
  background: rgba(0, 0, 0, 0.15);
  display: inline-block;
  padding: 0.25rem 0.55rem;
}
.inactiveFlBtn {
  background-color: rgb(55, 55, 55);
}
.inactiveFlBtn:hover {
  background-color: #797777ec;
}
.activeFlBtn {
  background-color: #068b6a;
  font-weight: 600;
}
.fileHeader {
  color: white;
  padding: 5px 10px;
  display: inline-block;
  font-size: 20px;
  border-radius: 4px;
  margin: 5px 0px;
}
.nofilenoradec {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
}
.givesomeMarginTop {
  margin-top: 10px;
  margin-bottom: 10px;
}
.tableContainer22 {
  max-height: 330px;
  overflow-y: scroll;
  border-bottom: 1px solid rgb(230, 229, 229);
  margin-bottom: 16px;
}
.maxsize2k {
  font-size: 12px;
  font-weight: 500;
  margin-top: 10px;
  margin-right: 10px;
}
.hw100100 {
  width: 100%;
  height: 100%;
  min-height: 800px;
  border: 0;
}
.allFrnCkbk {
  margin-right: 5px;
  transform: scale(1.3);
}
.tableCordstatus {
  text-align: center;
}
.sampleFiles {
  margin-top: 6px;
  border: 1px solid black;
}
.sampleFiles pre {
  margin: 0;
}
.sepButtonsEnds {
  display: flex;
  justify-content: space-between;
}
.alignItmsEnd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
.alignItemsSideByside {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.fileandDelBtn {
  display: flex;
  align-items: center;
}

.fileandDelBtn span {
  color: white;
  /* background-color: #dc3545; */
  background-color: rgb(55, 55, 55);
  border-radius: 5px;
  padding: 2px 6px 4px;
  cursor: pointer;
}
.fileandDelBtn span:hover {
  background-color: #c82333;
}

.confDelMod label {
  font-size: 24px;
}
.confDelMod .myButton {
  margin-top: 10px;
}
.confDelMod label span {
  color: #dc3545;
  margin-left: 8px;
  font-weight: 700;
}
.zoomIconFovHov {
  background-color: rgb(13, 162, 162);
  color: white;
  border-radius: 3px;
  padding-left: 4px;
  padding-right: 1px;
  cursor: pointer;
}
.zoomOnHover {
  transition: transform 0.2s;
  transform: scale(1.3);
  border: 1px solid black;
  border-radius: 5px;
  position: relative;
  left: 20px;
  z-index: 1000000 !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
}

.zoomOnHover22 {
  transition: transform 0.2s;
  transform: scale(1.1);
  position: relative;
  left: 20px;
  top: -20px;
  border: 1px solid black;
  border-radius: 5px;
  z-index: 1000000 !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
}

/* Tooltip container */
.mytooltipxx {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.mytooltipxx .tooltiptextxwfdsf {
  font-size: 12px;
  visibility: hidden;
  width: 350px;
  background-color: #00779f;
  box-shadow: 0px 0px 4px 0px black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 2px 3px;
  position: absolute;
  z-index: 1;
  top: 110%;
  left: 0;
  margin-left: 0px;
}
.mytooltipxx .tooltiptextxwfdsf::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 20%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #00779f transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.mytooltipxx:hover .tooltiptextxwfdsf {
  visibility: visible;
}
.checkboxesSmallGap {
  margin-top: 14px !important;
  margin-bottom: 10px;
}
.checkboxesSmallGap input::-webkit-outer-spin-button,
.checkboxesSmallGap input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.checkboxesSmallGap input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
  padding: 0 2px !important;
  height: auto !important;
  border-radius: 2px !important;
}
.checkboxesSmallGap input[type="number"]:focus {
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25) !important;
}
.checkboxesSmallGap label {
  margin-bottom: 0 !important;
}

.showLRDate {
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 8px;
  font-style: italic;
}
/* .showLRDate span {
  border-bottom: 1px solid gray;
  padding: 0px 4px 2px;
} */

.showLRDate2 {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 8px;
  font-style: italic;
  min-width: 100px;
}
.dispinblockx {
  display: inline-block;
  text-align: right;
}
.clearIconHoldClean {
  display: inline-block;
  border: 1px solid rgb(222, 220, 220);
  border-radius: 2px;
  color: rgb(169, 167, 167);
  padding: 1px 4px 2px;
  transition: all 0.3s;
  cursor: pointer;
}
.clearIconHoldClean:hover {
  background-color: rgb(243, 242, 242);
  color: red;
}
.timingOutrSH {
  margin: 20px 5px 0px 0px;
}
.timingOutrSH p {
  font-weight: 600;
  font-size: 14px;
  margin: 0px 0px 3px;
  cursor: pointer;
}
/* when hover change the color of span  */
.timingOutrSH p:hover span {
  color: #00c897;
}
.timingplotztfHOld {
  max-height: 700px;
  transition: all 0.5s ease;
  border: 1.5px solid #aaa;
  border-radius: 4px;
  box-shadow: 0.5px 0.5px 0px #aaa;
  overflow: hidden;
}
.timingplotztfHOld img {
  width: 100%;
}
.marginBtnten {
  margin-bottom: 15px;
}
.clearIconHoldClean2 {
  display: inline-block;
  border: 1px solid rgb(241, 240, 240);
  border-radius: 3px;
  color: rgb(169, 167, 167);
  padding: 1px 1px 0px;
  position: relative;
  top: 2px;
  transition: all 0.1s;
  cursor: pointer;
  max-height: 24px;
}
.clearIconHoldClean2 svg {
  vertical-align: baseline !important;
}
.clearIconHoldClean2:hover {
  background-color: #76d8c1;
  border-color: #76d8c1;
  color: red;
}
.bgdisabledVer {
  background-color: #f1f1f1;
  color: #a1a1a1;
}
.aiButton {
  margin-bottom: 3px !important;
  background: linear-gradient(
      275.23deg,
      rgb(103, 109, 252) 18.88%,
      #03ae7b 94.85%
    )
    0% 0% no-repeat padding-box padding-box transparent;
  color: white;
  padding: 1px 14px !important;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 8px !important;
}
.aiButton:hover {
  background: linear-gradient(
      275.23deg,
      rgb(98, 92, 235) 18.88%,
      #03a071 94.85%
    )
    0% 0% no-repeat padding-box padding-box transparent;
}
.textcolorwhite {
  height: 100%;
  width: 100%;
  color: white;
}
.aiBg {
  background: linear-gradient(
      275.23deg,
      rgb(103, 109, 252) 18.88%,
      #03ae7b 94.85%
    )
    0% 0% no-repeat padding-box padding-box transparent;
}
.alksjfislfds {
  height: 100%;
  z-index: 0;
  padding-bottom: 108px;
}
.MuiDrawer-paper {
  /* margin-top: 72px !important; */
  background-color: black !important;
}
.inputProvider {
  position: fixed;
  bottom: 0px;
  width: 500px;
  padding: 16px 10px;
  display: flex;
  background-color: black;
}
.drawCloseBtn {
  background-color: #077a58;
  padding: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
}
.drawCloseBtn:hover {
  background-color: #049f70;
}
.alignCenterx {
  display: flex;
  justify-content: center;
  align-items: center;
}
.MuiOutlinedInput-input {
  background-color: white !important;
}
.displayvertically {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
}
.displayvertically button {
  padding: 0px 8px !important;
}
.displayvertically button:hover {
  color: #077a58 !important;
}
.verysmall {
  font-size: 11px;
  font-weight: 500;
  font-style: italic;
  color: rgb(88, 87, 87);
}
.smallwarningx {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0px;
  text-align: left;
  padding: 0px 6px;
  line-height: 1.1;
  color: #f00;
}

.showMoreinfoArea {
  background-color: white;
  text-align: center;
  border-radius: 4px;
  padding: 24px 0px;
  margin-top: 5px;
}

.tev_det_p {
  margin-bottom: 0;
  text-align: left;
  margin-left: 30px;
}